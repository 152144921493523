<template>
  <div class="container dispatch-warp flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/pfrw.png')" alt="" />
      <div class="head-bottom-warp">
        <div
          class="align-center head-item"
          v-for="(item, index) in list"
          :key="index"
          :class="activIndex == index ? 'blue' : 'black'"
          @click="handleClick(index)"
        >
          <div class="bg-blur"></div>
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <div class="date-bottom-warp">
      <div class="date-item d-around blue">
        <div class="bg-blur"></div>
        <div class="df-center">
          <van-dropdown-menu active-color="#3a86ff">
            <van-dropdown-item
              v-model="forms.time"
              :options="timeList"
              :title="titleOne"
              @change="handleChangeOne"
            />
          </van-dropdown-menu>
          <img
            class="img-jiantou"
            :src="require('@/assets/image/jiantou.png')"
            alt=""
          />
          <van-icon
            name="close"
            v-if="forms.time"
            @click="handleClose('time')"
          />
        </div>
        <div class="df-center">
          <van-dropdown-menu active-color="#3a86ff">
            <van-dropdown-item
              v-model="forms.type"
              :options="event_type"
              :title="titleTwo"
              @change="handleChangeTwo"
            />
          </van-dropdown-menu>
          <img
            class="img-jiantou"
            :src="require('@/assets/image/jiantou.png')"
            alt=""
          />
          <van-icon
            name="close"
            v-if="forms.type"
            @click="handleClose('event')"
          />
        </div>
      </div>
    </div>
    <div class="flexone">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
        v-if="dataList.length && dataList.length > 0"
        offset="50"
      >
        <div
          class="panelInfo-content"
          v-for="(item, index) in dataList"
          :key="index"
          @click="routerTo(item)"
        >
          <div class="panelInfo-top">
            <div class="panelInfoTop-left">
              <img
                :src="require('@/assets/image/path9.png')"
                alt=""
                class="path9-img"
              />
              <span class="panelInfoTop-name">{{ item.name }}</span>
            </div>
            <div class="panelInfoTop-right">
              <span
                class="yuandian yuandian-blue"
                v-if="item.level === 1"
              ></span>
              <span class="yuandian yuandian-red" v-else></span>
            </div>
          </div>
          <div class="panelInfo-bottom">
            <div class="panelInfoBottom-date">{{ item.time || "无" }}</div>
            <div class="panelInfoBottom-right">
              <img :src="require('@/assets/image/path6.png')" alt="" />
              <span class="f-s10 m-l8">{{
                $dt.changeCodeName("event_type", item.type)
              }}</span>
            </div>
          </div>
        </div></van-list
      >
      <div class="nothing" v-if="!dataList.length">
        <van-empty description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin/dispatchMixin";
import { mapState } from "vuex";
export default {
  mixins: [mixin],
  data() {
    return {
      list: ["未处理任务", "已处理任务"],
      interfaceUrl: "/api/event/get",
      otherParams: {
        assign_phone: "",
      },
    };
  },
  created() {
    this.otherParams.assign_phone = this.userInfo.loginAcc;
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {},
  methods: {
    routerTo(row) {
      if (this.activIndex === 0) {
        // 未处理
        this.$router.push({
          path: "dispatchTasksWaitDetail",
          query: {
            id: row.id,
          },
        });
      } else {
        this.$router.push({
          path: "dispatchTasksDetail",
          query: {
            id: row.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="less">
.dispatch-warp {
  background: rgb(248, 248, 248);
  letter-spacing: 0px;
  position: relative;
  .d-around {
    justify-content: space-around !important;
  }
  .img-jiantou {
    margin-left: 0 !important;
  }
  .path9-img {
    width: 16px;
    height: 14px;
    object-fit: cover;
  }
}
</style>
