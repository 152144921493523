import httpService from "@/plugins/http";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["event_type"]),
  },
  data() {
    return {
      loading: false,
      finished: false,
      forms: {
        time: "",
        begin: "",
        end: "",
        type: "",
      },
      activIndex: 0,
      titleOne: "请选择",
      titleTwo: "请选择",
      timeList: [
        {
          text: "本周",
          value: "1",
        },
        {
          text: "本月",
          value: "2",
        },
        {
          text: "本季度",
          value: "3",
        },
        {
          text: "本年",
          value: "4",
        },
      ],
      dataList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0, // 总条数
    };
  },
  methods: {
    handleClick(index) {
      this.currentReset();
      this.activIndex = index;
      this.getInit();
    },
    async getInit() {
      try {
        if (this.currentPage === 1) {
          this.$store.commit("showLoading");
        }
        let req = { ...this.forms };
        delete req.time;
        const res = await httpService.post(this.interfaceUrl, {
          ...req,
          area_id: [],
          start_page: this.currentPage, //开始页0开始
          size_per_page: this.pageSize,
          ...(this.otherParams || {}),
          state: this.activIndex
        });
        if (res.code === 0) {
          if (this.currentPage === 1) {
            this.dataList = res.data || [];
          } else {
            let records = res.data || [];
            this.dataList = this.dataList.concat(records);
          }
          if (this.activIndex === 0) {
            // 0是未处理，1是已处理
            this.dataList = this.dataList.filter((item) => {
              return item.state === 0;
            });
          } else {
            this.dataList = this.dataList.filter((item) => {
              return item.state == 1;
            });
          }
          this.total = Math.ceil(res.total / this.pageSize);
          this.currentPage = this.currentPage + 1;
          this.loading = false;
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.$store.commit("hideLoading");
      }
    },
    handleChangeOne(code) {
      console.log(code, "cdooeo");
      let obj = this.timeList.find((item) => {
        return item.value === code;
      });
      if (obj) {
        this.titleOne = obj.text;
      }
      if (this.forms.time === "1") {
        //本周
        this.checkWeekTime();
      } else if (this.forms.time === "2") {
        //本月
        this.checkMonthTime();
      } else if (this.forms.time === "3") {
        //本季度
        this.checkQuarterTime();
      } else if (this.forms.time === "4") {
        //本年
        this.checkYearTime();
      }
      this.currentReset();
      this.getInit();
    },
    handleChangeTwo(code) {
      console.log(code, "cdooeo");
      let obj = this.event_type.find((item) => {
        return item.value === code;
      });
      if (obj) {
        this.titleTwo = obj.text;
      }
      this.currentReset();
      this.getInit();
    },
    onLoad() {
      // 异步更新数据
      if (this.currentPage === 1) {
        this.getInit();
      } else {
        if (this.currentPage <= this.total) {
          this.getInit();
        } else {
          this.finished = true;
        }
      }
    },
    handleClose(val) {
      if (val === "time") {
        this.forms.time = "";
        this.forms.begin = "";
        this.forms.end = "";
        this.titleOne = "请选择";
      } else {
        this.forms.type = "";
        this.titleTwo = "请选择";
      }
      this.currentReset();
      this.getInit();
    },
    currentReset() {
      this.currentPage = 1;
      this.total = 0;
      this.loading = false;
      this.finished = false;
      this.dataList = [];
    },
    // 本周
    getCurrentDate() {
      // 获取当前时间
      return new Date();
    },
    formatDate(date) {
      // 格式化时间为yyyy-mm-dd
      var d = new Date(date);
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    checkWeekTime() {
      // 获取当前时间
      var currentDate = this.getCurrentDate();
      // 返回date是一周中的某一天
      var week = currentDate.getDay();
      // 返回date是一个月中的某一天
      // var month = currentDate.getDate()
      // 一天的毫秒数
      var millisecond = 1000 * 60 * 60 * 24;
      // 减去的天数
      var minusDay = week !== 0 ? week - 1 : 6;
      // alert(minusDay);
      // 本周 周一
      var monday = new Date(currentDate.getTime() - minusDay * millisecond);
      // 本周 周日
      var sunday = new Date(monday.getTime() + 6 * millisecond);
      console.log(this.formatDate(monday), this.formatDate(sunday));
      // 添加本周时间
      this.forms.begin = this.formatDate(monday) + " " + "00:00:00"; // 本周起始时间
      // 添加本周最后一天时间
      this.forms.end = this.formatDate(sunday) + " " + "23:59:59"; // 本周终止时间
    },
    // 本月
    checkMonthTime() {
      // 获取当前时间
      var currentDate = this.getCurrentDate();
      // 获得当前月份0-11
      var currentMonth = currentDate.getMonth();
      // 获得当前年份4位年
      var currentYear = currentDate.getFullYear();
      // 求出本月第一天
      var firstDay = new Date(currentYear, currentMonth, 1);

      // 当为12月的时候年份需要加1
      // 月份需要更新为0 也就是下一年的第一个月
      if (currentMonth === 11) {
        currentYear++;
        currentMonth = 0; // 就为
      } else {
        // 否则只是月份增加,以便求的下一月的第一天
        currentMonth++;
      }
      // 一天的毫秒数
      var millisecond = 1000 * 60 * 60 * 24;
      // 下月的第一天
      var nextMonthDayOne = new Date(currentYear, currentMonth, 1);
      // 求出上月的最后一天
      var lastDay = new Date(nextMonthDayOne.getTime() - millisecond);

      this.forms.begin = this.formatDate(firstDay) + " " + "00:00:00"; // 本月起始时间
      // 添加本周最后一天时间
      this.forms.end = this.formatDate(lastDay) + " " + "23:59:59"; // 本月终止时间
    },
    // 本季度
    checkQuarterTime() {
      // 获取当前时间
      var currentDate = this.getCurrentDate();
      // 获得当前月份0-11
      var currentMonth = currentDate.getMonth();
      // 获得当前年份4位年
      var currentYear = currentDate.getFullYear();
      // 获得本季度开始月份
      var quarterSeasonStartMonth =
        this.getQuarterSeasonStartMonth(currentMonth);
      // 获得本季度结束月份
      var quarterSeasonEndMonth = quarterSeasonStartMonth + 2;

      // 获得本季度开始的日期
      var quarterSeasonStartDate = new Date(
        currentYear,
        quarterSeasonStartMonth,
        1
      );
      // 获得本季度结束的日期
      var quarterSeasonEndDate = new Date(
        currentYear,
        quarterSeasonEndMonth,
        this.getMonthDays(currentYear, quarterSeasonEndMonth)
      );

      this.forms.begin =
        this.formatDate(quarterSeasonStartDate) + " " + "00:00:00"; // 本季度起始时间
      // 添加本周最后一天时间
      this.forms.end = this.formatDate(quarterSeasonEndDate) + " " + "23:59:59"; // 本季度终止时间
    },
    getQuarterSeasonStartMonth(month) {
      // 得到本季度开始的月份
      var spring = 0; // 春
      var summer = 3; // 夏
      var fall = 6; // 秋
      var winter = 9; // 冬
      // 月份从0-11
      if (month < 3) {
        return spring;
      }
      if (month < 6) {
        return summer;
      }
      if (month < 9) {
        return fall;
      }
      return winter;
    },
    getMonthDays(year, month) {
      // 获得该月的天数
      // 本月第一天 1-31
      var relativeDate = new Date(year, month, 1);
      // 获得当前月份0-11
      var relativeMonth = relativeDate.getMonth();
      // 获得当前年份4位年
      var relativeYear = relativeDate.getFullYear();

      // 当为12月的时候年份需要加1
      // 月份需要更新为0 也就是下一年的第一个月
      if (relativeMonth === 11) {
        relativeYear++;
        relativeMonth = 0;
      } else {
        // 否则只是月份增加,以便求的下一月的第一天
        relativeMonth++;
      }
      // 一天的毫秒数
      var millisecond = 1000 * 60 * 60 * 24;
      // 下月的第一天
      var nextMonthDayOne = new Date(relativeYear, relativeMonth, 1);
      // 返回得到上月的最后一天,也就是本月总天数
      return new Date(nextMonthDayOne.getTime() - millisecond).getDate();
    },
    //本年
    checkYearTime() {
      this.forms.begin = new Date().getFullYear() + "-01-01" + " " + "00:00:00"; // 本月起始时间
      this.forms.end = new Date().getFullYear() + "-12-31" + " " + "23:59:59"; // 本月终止时间
    },
  },
  mounted() {
    if (this.$route.query.type == 1) {
      this.activIndex = 1;
    }
    this.getInit();
  },
};
